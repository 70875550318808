<template lang="">
  <div class="grid">
    <div v-if="!showServico" class="col-12">
      <div class="flex">
        <h3>
          {{ categoria.categoriaNome }}
        </h3>
      </div>
      <DataTable
        stripedRows
        :paginator="true"
        :value="categoria.servicos"
        :loading="loading"
        :rows="5"
        :filters.sync="filtros"
        :globalFilterFields="['nome']"
        filterDisplay="menu"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="rowsPerPageOptions"
        currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} serviços"
        responsiveLayout="stack">
        <template #empty> Nenhum serviço encontrado. </template>
        <template #loading> Carregando. Por favor aguarde. </template>
        <template #header>
          <div class="flex flex-column sm:flex-row">
            <span class="p-input-icon-left mb-2 mr-2">
              <i class="pi pi-search" />
              <InputText
                v-model="filtros['global'].value"
                placeholder="Pesquisar"
                style="width: 100%" />
            </span>
            <Button
              type="button"
              icon="pi pi-filter-slash"
              label="Limpar"
              class="p-button-outlined mb-2"
              @click="limparFiltro" />
          </div>
        </template>
        <Column field="nome" header="Titulo" sortable>
          <template #body="{ data }">
            <div class="flex justify-content-between flex-wrap">
              <div class="flex align-items-center justify-content-start">
                <label>{{ data.nome }}</label>
              </div>
            </div>
          </template>
        </Column>
        <Column header="Visualizar">
          <template #body="{ data }">
            <div class="flex align-items-center justify-content-end">
              <Button
                v-tooltip.left="'Ver Serviço'"
                label="Ver Serviço"
                :placeholder="'Ver Serviço'"
                class="p-button p-button-submit"
                @click="acessarDetalhesServico(data)" />
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
    <div v-if="showServico" class="col-12">
      <ServicoDigitalDetalhe :servicoId="servicoId" />
    </div>
  </div>
</template>
<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api/'
import ServicoDigitalDetalhe from '@/views/servidor/servicosDigitais/ServicoDigitalDetalhe.vue'
export default {
  components: {
    ServicoDigitalDetalhe,
  },

  props: {
    categoria: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      loading: false,
      showServico: false,
      filtros: {},
      servicoId: null,
    }
  },

  computed: {
    rowsPerPageOptions() {
      return [5, 10, 25]
    },
  },

  created() {
    this.loading = true
    this.showServico = false
    this.initFiltros()
  },

  mounted() {},

  methods: {
    limparFiltro() {
      this.filtros['global'].value = ''
    },

    initFiltros() {
      this.filtros = {
        global: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.CONTAINS },
            { value: null, matchMode: FilterMatchMode.EQUALS },
          ],
        },
      }
      this.loading = false
    },

    acessarDetalhesServico(data) {
      this.servicoId = data.id
      this.showServico = true
    },

    retornar() {
      this.showServico = false
      this.servicoId = null
    },
  },
}
</script>

<style>
.right-align {
  text-align: right;
}
</style>
