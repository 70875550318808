export default class CategoriaService {
  constructor(http) {
    this._http = http
  }

  async listarCategorias(matriculaId) {
    const { data } = await this._http.get(`/api/categoria/${matriculaId}`)
    return data
  }
}
