<template lang="">
  <div>
    <Card>
      {{ categorias }}
      <template #content>
        <TabView v-if="categorias">
          <TabPanel
            v-for="(categoria, index) in categorias"
            :key="categoria.nome">
            <template #header>
              <div
                class="flex justify-content-center align-items-center gap-1"
                @click="mudarVisibilidade(index)">
                <i
                  style="margin-right: 5px"
                  :class="categoria.categoriaIcone" />
                <span>
                  {{ categoria.categoriaNome }}
                </span>
              </div>
            </template>
            <ServicoDigitalList ref="child" :categoria="categoria" />
          </TabPanel>
        </TabView>
        <div v-else>Nenhum serviço cadastrado</div>
      </template>
    </Card>
  </div>
</template>
<script>
import { watch } from 'vue-demi'
import CategoriaService from '@/service/CategoriaService'
import ServicoDigitalList from '@/views/servidor/servicosDigitais/ServicoDigitalList.vue'
import { matriculaStore } from '@/stores/matricula'

export default {
  components: {
    ServicoDigitalList,
  },

  setup() {
    const store = new matriculaStore()
    return { store }
  },

  data() {
    return {
      categorias: [],
      visible: true,
      matriculaId: this.store.matriculaSelecionada.id,
    }
  },

  created() {
    this.categoriaService = new CategoriaService(this.$http)
    this.carregarServicos()
    watch(
      () => this.store.matriculaSelecionada,
      (state) => {
        if (state != null) {
          this.matriculaId = this.store.matriculaSelecionada.id
          this.carregarServicos()
        }
      },
      { deep: true },
    )
  },

  mounted() {
    this.carregarServicos()
  },

  methods: {
    carregarServicos() {
      if (this.matriculaId != null) {
        this.categoriaService
          .listarCategorias(this.matriculaId)
          .then((res) => {
            this.categorias = res
          })
          .catch((err) => {
            this.showToastErro(err)
          })
      }
    },

    mudarVisibilidade(index) {
      this.$refs.child[index].retornar()
    },

    showToastErro(err) {
      const mensagem = err.response
        ? err.response.data.message
        : 'Erro de conexão com a aplicação.'
      this.$toast.add({
        severity: 'error',
        summary: mensagem,
        life: 10000,
      })
    },
  },
}
</script>
<style lang=""></style>
